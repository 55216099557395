@charset "utf-8";

body {
  /* position: fixed !important; */
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  background-color: #0d0c2b;
  /* min-width: 960px; */
  /* overflow: hidden;*/
  overflow-y: hidden;
}

.section {
  width: 100%;
  /*height: 100%;*/
  position: relative;
}

::-webkit-scrollbar {
  display: none;
}

.scrollbar-track-y {
  background: transparent;
}

.scrollbar-thumb {
  background-color: #6648ff;
}

header {
  position: fixed;
  top: 0;
  width: 100%;
  padding: 30px 60px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 10000000;
  transform: initial;
  transition: transform 1s;
  background-color: rgba(0, 0, 0, 0.5);
  /*background: transparent;*/
  /* mix-blend-mode: difference; */
  /* transition: background-color 0.7s ease; */
}

header.scrollDown {
  transform: translate(0, -160px);
}

header .gnb > a {
  min-width: 100px;
}

header .gnb-menu {
  display: flex;
  align-items: center;
  gap: 4vw;
  font-size: 1.7rem;
  transform: translateY(3px);
}
header .gnb-menu a {
  color: #fff;
  font-weight: 900;
  white-space: nowrap;
}
header.black .gnb-menu a {
  color: #000;
}
header .business {
  padding: 10px 20px 8px 20px;
  border-radius: 20px;
  background-color: #3a4ada;
  font-size: 1.4rem;
  display: flex;
  align-items: center;
  gap: 10px;
}
header .business img {
  width: 12px;
  transform: translateY(-2px);
}
header .business h3 {
  color: #fff;
  font-weight: 900;
  font-size: 19px;
  white-space: nowrap;
}

header .gnb img {
  width: 180px;
}
header .nav-menu {
  cursor: pointer;
}
header .nav-menu img {
  width: 40px;
  mix-blend-mode: difference;
}

header.bg {
  background: #000120;
}

header .header-right {
  display: flex;
  align-items: center;
  gap: 10px;
}

header .header-right .mobile-menu {
  display: none;
}

header .header-right .mobile-menu img {
  width: 30px;
}
.scrollbar-track-x {
  display: none !important;
}

.polygon {
  position: absolute;
  width: 100%;
  height: 100px;
  bottom: 0;
  background: url(../img/polygon.png) no-repeat 50% 50%;
  background-size: cover;
}

.footer {
  position: relative;
  width: 100vw;
  height: 45vh;
  background: #0d0c2b;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 5vw;
  text-align: left;
  gap: 40px;
}
.footer .logo {
  padding: 0 0 40px 0;
  width: 100%;
  border-bottom: 1px solid #33344d;
}
.footer .logo img {
  width: 200px;
}
.footer .content {
  width: 100%;
  display: flex;
  color: #b9b9b9;
  align-items: flex-end;
  justify-content: space-between;
}
.footer .content > div > div {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 10px;
}
.footer .content > div > div > img {
  width: 14px;
}
.footer .content > div > div > p {
  font-weight: 600;
}
.footer .content div > p:not(:last-child) {
  padding-bottom: 10px;
}
.footer .content div > p > span {
  font-weight: 800;
  margin-right: 12px;
}
.footer .content div > h3 {
  padding-bottom: 24px;
  font-weight: 800;
  font-size: 2rem;
}
@keyframes footerAni {
  0% {
    color: #103766;
  }

  100% {
    color: #fff;
  }
}

@keyframes footerUnder {
  0% {
    width: 0%;
  }

  100% {
    width: 100%;
  }
}

.footer .title h1.ani > span::after {
  animation: footerUnder 1.6s ease-in-out forwards;
}
.footer .info {
  color: #b6b6bf;
}
.footer .info h3 {
  color: #b6b6bf;
  font-size: 2.8rem;
  font-weight: 700;
  margin-bottom: 20px;
}

.footer .info .info-list > div {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 20px;
}
.footer .info .info-list > div:last-child {
  margin-bottom: 0;
}
.footer .contact {
  width: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
}

.footer .contact > h3 {
  color: #b6b6bf;
  font-size: 2.4rem;
  font-weight: 700;
}
.footer .copyright {
  font-weight: 700;
}

/*menu*/
@keyframes showMenu {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
