@media screen and (max-width: 420px) {
  header {
    padding: 30px 18px;
  }
  header .gnb-menu {
    display: none;
  }
  .main-wrapper .main-title h3 {
    font-size: 2rem;
  }
  .main-wrapper .main-title h1 {
    font-size: 6rem;
  }
  .main-wrapper .main-title {
    left: 10vw;
  }
  header .gnb img {
    width: 100px;
  }
  header .business {
    padding: 8px 10px;
    font-size: 1rem;
  }
  .main-wrapper2 .scale-txt {
    font-size: 5rem;
  }
  .main-wrapper2 {
    height: 30vh;
  }
  .main-wrapper3 h1 {
    font-size: 2.4rem;
    height: 24px;
  }
  .main-wrapper3 {
    height: 50vh;
    padding: 20px 0;
  }
  .main-wrapper3 p {
    font-size: 1.6rem;
    height: 20px;
  }
  .main-wrapper4 {
    height: 60vh;
  }
  .main-wrapper4 .category .txt-wrapper .title {
    width: 90%;
  }
  .main-wrapper4 .category .txt-wrapper .title img {
    width: 30px;
  }
  .main-wrapper4 .category .txt-wrapper .title .name h1 {
    font-size: 2.2rem;
  }
  .main-wrapper4 .category .txt-wrapper .title .name p {
    font-size: 1rem;
  }
  .main-wrapper4 .category .txt-wrapper .title .line {
    width: 20px;
  }
  .main-wrapper4 .detail-wrapper > div {
    font-size: 1rem;
  }
  .main-wrapper4 .category .icon {
    width: 30px;
  }
  .main-wrapper4 .category.num-4.on {
    right: 3%;
  }
  .main-wrapper4 .detail-wrapper > div {
    padding: 10px 0;
  }
  .main-wrapper5 {
    height: 78vh;
  }
  .main-wrapper5 .title h3 {
    white-space: nowrap;
    height: 30px;
    font-size: 3rem;
  }
  .main-wrapper5 .title h1 {
    font-size: 2rem;
    margin-bottom: 15px;
  }
  .partner-slider {
    margin-top: 6vh;
  }
  .partner-slider img {
    width: 80px;
  }
  .partner-slider .slide {
    margin: 14px 20px;
    width: 130px;
    height: 36px;
  }

  .footer {
    height: 28vh;
  }
  .footer .content {
    font-size: 1.2rem;
  }
  .footer .content > div:last-child {
    text-align: right;
  }
  .footer .logo {
    padding: 0 0 20px 0;
  }
  .footer .logo img {
    width: 140px;
  }
  .about-wrapper1 {
    height: 60vh;
    background-position: 30% 70%;
  }
  .about-wrapper1 .polygon {
    display: none;
  }
  .about-wrapper1 .sub-title {
    font-size: 2rem;
  }
  .about-wrapper1 .title {
    font-size: 5rem;
  }
  .about-wrapper1 .title .third {
    width: 110px;
  }
  .about-wrapper1 .title h1 {
    height: 54px;
  }
  .about-wrapper1 .detail {
    font-size: 1.2rem;
  }
  .about-wrapper1 .detail {
    margin-top: 3vh;
    padding: 0 10px;
  }
  .about-wrapper3 .history-top .year h3 {
    font-size: 2.6rem;
  }
  .year-wrapper:last-child > div:first-child::before {
    height: 18px;
    width: 18px;
  }
  .year-wrapper > div:after {
    height: 18px;
    width: 18px;
  }
  .about-wrapper3 {
    height: 65vh;
  }
  .about-wrapper3 .history-top .history-prev {
    width: 20px;
    height: 20px;
  }
  .about-wrapper3 .history-top .history-next {
    width: 20px;
    height: 20px;
  }
  .about-wrapper3 .history-bottom h1 {
    font-size: 6rem;
    left: -18%;
    top: 32%;
  }
  .about-wrapper3 .history-bottom .list {
    padding: 20% 0;
  }
  .about-wrapper3 .history-bottom .list div:not(.next-btn) {
    font-size: 1.4rem;
  }
  .about-wrapper3 .history-bottom .list div:not(.next-btn)::before {
    width: 5px;
    height: 5px;
  }
  .about-wrapper6 {
    height: 55vh;
    gap: 5vh;
  }
  .about-wrapper6 .card-wrapper {
    display: block;
    text-align: center;
  }
  .about-wrapper6 > h1 {
    font-size: 3rem;
  }
  .about-wrapper6 .card-wrapper > div {
    height: 15vh;
    display: inline-block;
    text-align: center;
    width: 48%;
  }
  .about-wrapper6 .card-wrapper > div h3 {
    font-size: 1.6rem;
  }
  .about-wrapper6 .card-wrapper > div > img {
    width: 20%;
    margin-bottom: 12px;
    margin-top: 12px;
  }
  .about-wrapper6 .card-wrapper > div .sub-title {
    font-size: 1rem;
  }
  .about-wrapper7 {
    padding: 6vh 10vw;
  }
  .about-wrapper7 .partners-box {
    margin-top: 60px;
  }
  .about-wrapper7 .title h1 {
    font-size: 2.4rem;
  }
  .about-wrapper7 .title h3 {
    font-size: 8px;
  }
  .about-wrapper7 .partners-box > div > div {
    padding: 4px 8px;
  }
  .about-wrapper7 .partners-box > div {
    margin-bottom: 30px;
  }
  .about-wrapper7 .view-more {
    width: 12px;
    height: 12px;
    bottom: calc(20vh - 82px);
  }
  .about-wrapper7 .view-more.min {
    width: 12px;
    height: 12px;
  }
  .about-wrapper8 {
    height: auto;
    padding: 30px 0;
  }
  .about-wrapper8 .title {
    font-size: 3rem;
  }
  .about-wrapper8 .info {
    width: 74vw;
  }
  .about-wrapper8 .info > div {
    padding: 10px;
    font-size: 1rem;
  }
  .map-bg {
    margin-top: 35px;
    height: 20vh;
  }

  .career-wrapper1 .title h1 {
    font-size: 5rem;
  }
  .career-wrapper1 .title h3 {
    font-size: 1.4rem;
  }

  .career-wrapper2 {
    flex-direction: column;
    padding: 60px 20px;
  }
  .career-wrapper2 .title h1 {
    margin-bottom: 8px;
  }
  .career-wrapper2.ani .title h1:not(.benefits) {
    height: 46px;
  }
  .career-wrapper2 .title h1:last-child > span::after {
    width: 48px;
    height: 48px;
  }
  .benefits-list {
    width: 100%;
    margin-top: 40px;
  }
  .benefits-list ul li {
    height: 56px;
    padding: 0 10px;
  }
  .benefits-list ul li h3 {
    font-size: 1.6rem;
  }
  .career-wrapper2 .polygon {
    display: none;
  }
  .benefits-list ul li:hover {
    height: 56px;
  }
  .benefits-list ul li p {
    margin-left: 10px;
    font-size: 6px;
    max-width: 170px;
    white-space: initial;
    word-break: break-all;
  }
  .career-wrapper3 {
    padding: 30px;
    height: 100vh;
  }
  .career-wrapper3 .steps-wrapper {
    height: calc(100% - 10vh - 220px);
  }
  .career-wrapper3 .steps-wrapper > div h4 {
    margin: 11px 0px 20px 22px;
    font-size: 1.4rem;
  }
  .career-wrapper3 .steps-wrapper > div p {
    width: auto;
    font-size: 1rem;
    margin: 0 10px;
  }
  .career-wrapper3 .steps-wrapper > div h4 > span {
    font-size: 1.2rem;
  }
  .career-wrapper3 .steps-wrapper > div img:not(.line) {
    width: 40px;
  }
  .career-wrapper4 .title h3 {
    font-size: 2rem;
  }
  .career-wrapper4 .title h1 {
    font-size: 3.4rem;
  }
  .career-wrapper4 .email {
    width: auto;
  }
  .career-wrapper4 .map-link {
    padding: 4px 7px 8px 16px;
    font-size: 1.6rem;
  }

  .product-wrapper1 .title h1 {
    font-size: 4rem;
  }
  .product-wrapper1 .title h3 {
    font-size: 2rem;
  }
  .main-wrapper4 .product-detail {
    width: 90%;
    margin-top: 26px;
  }
  .main-wrapper4 .category .txt-wrapper {
    width: 80%;
  }
  .main-wrapper4 .product-detail h3 {
    font-size: 1.6rem;
  }
  .main-wrapper4 .product-detail p {
    font-size: 4px;
  }
  .main-wrapper4 .category .txt-wrapper > p {
    width: 90%;
    font-size: 1.4rem;
  }
  .product-link > div a {
    font-size: 1rem;
  }
  .contact-wrapper .contact-box {
    display: block;
    margin: 3vh 0;
  }
  .contact-wrapper .contact-box > div {
    font-size: 1rem;
    padding: 6px 20px;
    border-right: none;
  }
  .contact-wrapper2 .title .sub-title {
    height: 156px;
    font-size: 2.8rem;
  }
  .contact-wrapper2 .title .main {
    font-size: 30px;
  }
  .contact-wrapper2 .title .main h1 {
    height: 42px;
  }
  .contact-wrapper2 .title .main h1 > span {
    transform: translateY(42px);
  }
  .contact-wrapper2 .email {
    font-size: 1.4rem;
  }
  .contact-wrapper2 .email span {
    font-size: 1.2rem;
  }
  .contact-wrapper2 .link {
    height: 30vh;
  }
  .link-wrapper .link img {
    width: 36px;
  }
  .link-wrapper .link h3 {
    font-size: 2rem;
  }
  .link-wrapper .link a {
    font-size: 1.4rem;
  }
}
