/*Main Page*/
.main-mov {
  position: absolute;
  width: 100vw;
  height: 100vh;
  object-fit: cover;
  z-index: -1;
}

.main-wrapper {
  position: relative;
  width: 100%;
  height: 100vh;
  white-space: nowrap;
}

.main-wrapper .main-title {
  position: absolute;
  top: 48%;
  left: 100px;
  transform: translateY(-50%);
}

.main-wrapper .main-title h3 {
  font-size: 5rem;
  font-weight: 700;
  margin-bottom: 46px;
  color: #fff;
}

.main-wrapper .main-title h1 {
  font-size: 12rem;
  color: #fff;
  font-weight: 900;
  letter-spacing: 0;
}

.cursor {
  color: #fff;
}

@keyframes blink {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 100%;
  }

  100% {
    opacity: 0%;
  }
}

.box1 {
  height: 2000px;
}

.box {
  width: 300px;
}

.box2 img {
  width: 300px;
  height: auto;
}

.cursor.blink {
  animation: blink 1s 1s infinite ease;
}

.main-wrapper2 {
  width: 100%;
  height: 100vh;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 !important;
  overflow: hidden;
  background-color: #000120;
}

.main-wrapper2 .grad {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 10;
}

.main-wrapper2 .scale-txt {
  position: relative;
  align-items: center;
  /* padding: 200px 20% !important; */
  font-size: 18rem;
  color: #fff;
  font-weight: 900;
  z-index: 100;
}

.main-wrapper2 .scale-txt h1 {
  /* width: 100%; */
  text-align: left;
  width: fit-content;
}

.main-wrapper2 .scale-txt .zs {
  /* display: flex; */
  /* justify-content: flex-end; */
}

.main-wrapper2 .scale-txt .zs .blue {
  color: #3a4ada;
}

.main-wrapper2 .scale-txt .zs .mark {
  transform: rotate(15deg);
}

/* .main-wrapper2 .scale-txt .zs .ico {
  width: 16rem;
  height: 16rem;
  background: url(../img/ico-why.png) no-repeat; */

.main-wrapper2 .scale-txt > h1:last-child {
  margin-left: 100px;
  text-align: right;
}

.main-wrapper3 {
  width: 103vw;
  padding: 100px 0;
  height: 100vh;
  background: #000120 url(../img/main-bg3.png) 50% 50% no-repeat;
  background-size: cover;
  opacity: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.main-wrapper3 h1 {
  color: #fff;
  font-size: 5rem;
  line-height: 1.5;
  font-weight: 700;
  height: 60px;
  overflow: hidden;
  margin-bottom: 10px;
}
.main-wrapper3 > h1:last-of-type {
  margin-bottom: 28px;
}

.main-wrapper3 p {
  color: #b9b9b9;
  font-size: 2.8rem;
  letter-spacing: 120%;
  height: 40px;
  overflow: hidden;
  margin-bottom: 10px;
}

.main-wrapper4 {
  position: relative;
  width: 100%;
  overflow: hidden;
  height: 100vh;
  background-color: #0d0c2b;
}

.main-wrapper4 .category {
  position: absolute;
  height: 100%;
  width: 70%;
  clip-path: polygon(0% 0%, 0% 100%, 80% 100%, 100% 0%);
  transition: transform 0.8s 0.1s cubic-bezier(0.57, -0.01, 0.39, 0.99),
    background-image 0.4s 0.4s ease;
  display: flex;
  justify-content: center;
}

.main-wrapper4 .category:not(.num-1) {
  clip-path: polygon(20% 0%, 0% 100%, 80% 100%, 100% 0%);
}

.main-wrapper4 .category.num-4 {
  clip-path: polygon(20% 0%, 0% 100%, 100% 100%, 100% 0%);
  justify-content: flex-end;
}

.main-wrapper4 .category.num-1 {
  background: url(../img/category-1.png) 50% 50% no-repeat;
  background-size: cover;
  left: 0;
  z-index: 10;
}

.main-wrapper4 .category.num-1.on {
  background: url(../img/category-1-on.png) 50% 50% no-repeat;
  background-size: cover;
  z-index: 10;
}

.main-wrapper4 .category.num-2 {
  background: url(../img/category-2.png) 50% 50% no-repeat;
  background-size: cover;
  left: 14%;
  z-index: 9;
}

.main-wrapper4 .category.num-2.on {
  background: url(../img/category-2-on.png) 50% 50% no-repeat;
  background-size: cover;
}

.main-wrapper4 .category.num-3 {
  background: url(../img/category-3.png) 50% 50% no-repeat;
  background-size: cover;
  left: 28%;
  z-index: 8;
}

.main-wrapper4 .category.num-3.on {
  background: url(../img/category-3-on.png) 50% 50% no-repeat;
  background-size: cover;
}

.main-wrapper4 .category.num-4 {
  background: url(../img/category-4.png) 50% 50% no-repeat;
  background-size: cover;
  right: 0;
  z-index: 7;
}

.main-wrapper4 .category.num-4.on {
  background: url(../img/category-4-on.png) 50% 50% no-repeat;
  background-size: cover;
  right: 0%;
  left: auto;
}

.main-wrapper4 #product-1 {
  width: 80%;
  background: url(../img/product-bg-1.png) 50% 50% no-repeat;
  background-size: cover;
  left: 0;
  z-index: 10;
}

.main-wrapper4 #product-1.on {
  background: url(../img/product-bg1-on.png) 50% 50% no-repeat;
  background-size: cover;
  left: 0;
  z-index: 10;
}

.main-wrapper4 #product-2 {
  width: 80%;
  background: url(../img/product-bg-2.png) 50% 50% no-repeat;
  background-size: cover;
  left: 16%;
  z-index: 9;
}

.main-wrapper4 #product-2.on {
  background: url(../img/product-bg2-on.png) 50% 50% no-repeat;
  background-size: cover;
}

.main-wrapper4 #product-3 {
  width: 80%;
  background: url(../img/product-bg-3.png) 50% 50% no-repeat;
  background-size: cover;
  left: 28%;
  z-index: 8;
  clip-path: polygon(0% 0%, 0% 100%, 100% 100%, 100% 0%);
}

.main-wrapper4 #product-3 .txt-wrapper {
  margin-left: 7%;
}

.main-wrapper4 #product-3 .txt-wrapper .title {
  width: 74%;
}

.main-wrapper4 #product-3 .product-detail {
  width: 74%;
}

.main-wrapper4 #product-3 .txt-wrapper > p {
  width: 74%;
}

.main-wrapper4 #product-3 .product-link {
  width: 74%;
}

.main-wrapper4 #product-3.on {
  background: url(../img/product-bg3-on.png) 50% 50% no-repeat;
  background-size: cover;
}

.main-wrapper4 #product-3 .icon {
  right: 19%;
}

.main-wrapper4 .product-detail {
  margin-top: 50px;
  margin-bottom: 60px;
  width: 80%;
}

#product-2 .product-detail {
  margin-top: 100px;
}

#product-3 .product-detail {
  margin-top: 100px;
}

.main-wrapper4 .product-detail h3 {
  color: #d9d9d9;
  font-weight: 700;
  font-size: 2.4rem;
  line-height: 1.4;
}

.main-wrapper4 .product-detail p {
  color: #d9d9d9;
  margin-top: 20px;
  font-size: 1.8rem;
  line-height: 1.6;
}

.main-wrapper4 .category .txt-wrapper {
  position: relative;
  width: 70%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: all 0.4s 0.4s ease;
}

.main-wrapper4 .category .icon {
  width: 50px;
  position: absolute;
  bottom: 56px;
  right: 26%;
  opacity: 1;
  transition: all 0.4s 0.4s ease;
}

.main-wrapper4 .category.on .icon {
  opacity: 0;
}

.main-wrapper4 .category:last-child > .icon {
  right: 6%;
}

.main-wrapper4 .category.num-1 .txt-wrapper {
  margin-right: 15%;
  opacity: 1;
}

.main-wrapper4 .category .txt-wrapper .title {
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 81%;
}

.main-wrapper4 .category .txt-wrapper .title img {
  width: 80px;
}

.main-wrapper4 .category .txt-wrapper .title .line {
  width: 100px;
  border-top: 1px solid #888;
}

.main-wrapper4 .category .txt-wrapper .title .name p {
  font-size: 2rem;
  text-align: right;
  color: #3a4ada;
  font-weight: 800;
  margin-bottom: 16px;
}

.main-wrapper4 .category .txt-wrapper .title .name h1 {
  font-size: 4rem;
  font-weight: 900;
  text-align: right;
  line-height: 1.3;
  white-space: nowrap;
}

.main-wrapper4 .category .txt-wrapper > p {
  width: 80%;
  font-size: 2rem;
  text-align: right;
  color: #3a4ada;
  font-weight: 700;
  margin-bottom: 20px;
}

.main-wrapper4 .detail-wrapper {
  width: 80%;
  margin-top: 60px;
}

.main-wrapper4 .detail-wrapper > div {
  display: flex;
  color: #d9d9d9;
  align-items: center;
  padding: 20px 16px 16px 16px;
  border-top: 1px solid #666;
  gap: 20px;
  font-size: 1.7rem;
  font-weight: 600;
}

.main-wrapper4 .detail-wrapper > div:last-child {
  border-bottom: 1px solid #666;
}

.main-wrapper4 .detail-wrapper img {
  width: 20px;
}

.main-wrapper4.product {
  position: relative;
  width: 100%;
  overflow: hidden;
  height: auto;
  background-color: #000120;
}

.main-wrapper4 .product-name {
  position: absolute;
  top: 100px;
  width: 100%;
  overflow: hidden;
  height: 50px;
  background-color: #0c1a2b;
  z-index: 10;
  display: flex;
  color: #d9d9d9;
  align-items: center;
  justify-content: center;
  gap: 10vw;
}

.main-wrapper4 .txt-wrapper {
  width: 100%;
  height: auto;
}

.main-wrapper4 .txt-wrapper .txt-box {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  height: 100vh;
}

.main-wrapper4 .txt-wrapper .txt-box .txt {
  position: relative;
  width: 50%;
  height: 100%;
  padding-left: 10vw;
  padding-top: 10vw;
  background-color: #0d0c2b;
  color: #fff;
  text-align: left;
}

.main-wrapper4 .txt-wrapper .txt-box .txt h3 {
  font-size: 2.8rem;
  font-weight: 700;
  margin-bottom: 30px;
}

.main-wrapper4 .txt-wrapper .txt-box .txt h1 {
  font-size: 4rem;
  font-weight: 700;
  line-height: 1.3;
}

.main-wrapper4 .txt .detail {
  margin-top: 80px;
  color: #d9d9d9;
  letter-spacing: 0;
}

.main-wrapper4 .txt .detail > div {
  display: flex;
  align-items: center;
  gap: 10px;
  margin: 16px 0;
}

.main-wrapper4 #product-1 .product-link > div {
  padding-left: 0;
}
.main-wrapper5 {
  width: 100vw;
  height: 118vh;
  background: #000120;
  position: relative;
}

.main-wrapper5 .title {
  padding-top: 24vh;
  padding-left: 10vw;
  color: #fff;
  width: 100%;
  text-align: left;
}

.main-wrapper5 .title h1 {
  font-size: 3rem;
  font-weight: 700;
  color: #888888;
  margin-bottom: 30px;
}

.main-wrapper5 .title h3 {
  color: #fff;
  font-size: 5.2rem;
  font-weight: 800;
  margin: 10px 0;
  height: 50px;
  overflow: hidden;
}

.main-wrapper5 .title .you {
  display: inline-block;
  color: #3a4ada;
  padding: 0 10px;
}

.partner-slider {
  margin-top: 22vh;
  transform: rotate(5deg);
  white-space: nowrap;
}

.partner-slider .slide {
  width: 300px !important;
  height: 100px;
  background-color: #0d0c2b;
  display: flex !important;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 20px;
  transition: all 0.4s ease;
}

.partner-slider .slide:hover {
  background: #6648ff;
}

.partner-slider .slide:hover > img {
  filter: brightness(30%) invert();
}

.partner-slider img {
  width: 180px;
}

/*Product*/
.product-wrapper1 {
  position: relative;
  width: 100vw;
  height: 100vh;
  background: url(../img/product-main-bg.png) 50% 50% no-repeat;
  background-size: cover;
}

.product-wrapper1 .title {
  position: absolute;
  top: 48%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #fff;
  text-align: center;
}

.product-wrapper1 .title h3 {
  font-weight: 700;
  color: #888;
  font-size: 3rem;
  margin-bottom: 30px;
  height: 30px;
  overflow: hidden;
}

.product-wrapper1 .title h3 > span {
  transform: translateY(34px);
  animation: slideUp 0.4s 0.2s ease forwards;
}

.product-wrapper1 .title h1 {
  font-size: 10.6rem;
  font-weight: 800;
  line-height: 1.2;
  opacity: 0;
  transform: translateY(40px);
  white-space: nowrap;
  animation: showTitle 0.6s 0.5s ease forwards;
}

.product-wrapper1 .title h1 > span {
  color: #3a4ada;
}
@keyframes scrollDown {
  50% {
    opacity: 1;
    bottom: 90px;
  }
  100% {
    opacity: 0;
    /* bottom: 70px; */
  }
}
.product-wrapper1 .scroll-bottom {
  position: absolute;
  left: 50%;
  bottom: 100px;
  transform: translateX(-50%);
  display: flex;
  gap: 10px;
  align-items: center;
  opacity: 0;
  animation: scrollDown 2.4s 0.7s ease infinite;
}

.product-wrapper1 .scroll-bottom p {
  padding-top: 4px;
  font-weight: 700;
  color: #b9b9b9;
}
.product-link {
  width: 80%;
  display: flex;
}

.product-link > div {
  display: flex;
  align-items: center;
  padding: 0 30px 0 0;
  border-right: 1px solid #fff;
  gap: 10px;
}

.product-link > div img {
  width: 24px;
}

.product-link > div a {
  color: #fff;
  font-weight: 800;
  font-size: 1.4rem;
}
.product-link > div a:hover {
  text-decoration: underline;
}
.product-link > div:last-child {
  border: none;
  padding-left: 30px;
}

.product-link > div:last-child img {
  width: 20px;
}

/*careers*/

@keyframes opacity {
  100% {
    opacity: 1;
  }
}

@keyframes showTitle {
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.career-wrapper1 {
  position: relative;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  background: url(../img/career-bg.png) no-repeat 50% 50%;
  background-size: cover;
}

.career-wrapper1 .filter {
  width: 100%;
  height: 100%;
  background: url(../img/career-bg-filter.png) no-repeat 50% 50%;
  background-size: cover;
  opacity: 0.5;
  animation: opacity 0.4s 0.1s ease forwards;
}

.career-wrapper1 .title h1 {
  opacity: 0;
  transform: translateY(80px);
  animation: showTitle 1s 0.3s ease forwards;
}

.career-wrapper1 .title h3 {
  opacity: 0;
  animation: opacity 0.8s 1s ease forwards;
}

.career-wrapper1 img {
  position: absolute;
  width: 100vw;
  height: 100vh;
}

.career-wrapper1 .title {
  position: absolute;
  left: 8vw;
  top: 50%;
  transform: translateY(-50%);
  color: #fff;
}

.career-wrapper1 .title h1 {
  font-size: 8.4rem;
  font-weight: 900;
  line-height: 1.3;
}

.career-wrapper1 .title h3 {
  font-size: 2.2rem;
  line-height: 2;
  margin-top: 40px;
}

.career-wrapper1 .title h3 > span {
  font-weight: 700;
}

.career-wrapper2 {
  position: relative;
  width: 100vw;
  min-height: 100vh;
  padding: 100px 14vh 15vh 14vh;
  background-color: #fff;
  display: flex;
  justify-content: space-between;
}

.career-wrapper2 .title h1 {
  color: #000;
  font-size: 6rem;
  font-weight: 900;
  margin-bottom: 12px;
  letter-spacing: -2px;
}

.career-wrapper2 .title h1:first-child {
  color: #3a4ada;
}

.career-wrapper2 .title h1:last-child > span::after {
  content: " ";
  display: inline-block;
  background: url(../img/arrow-r-blue.png) no-repeat 50% 50%;
  background-size: cover;
  width: 65px;
  height: 65px;
  vertical-align: text-top;
}

.career-wrapper2 .title h1:not(.benefits) > span {
  transform: translateY(60px);
  opacity: 0;
}

.benefits-list {
  width: calc(100% - 330px);
}

.benefits-list ul {
  width: 100%;
}

.benefits-list ul li {
  width: 100%;
  height: 75px;
  display: flex;
  align-items: center;
  padding: 0 40px;
  /* border-top: 1px solid #d9d9d9; */
  border-bottom: 1px solid #ccc;
  transition: all 0.4s ease;
  white-space: nowrap;
  position: relative;
}
.benefits-list ul li:first-of-type {
  border-top: 1px solid #d9d9d9;
}

.benefits-list ul li h3 {
  color: #000 !important;
  font-size: 2.8rem;
  font-weight: 900;
  transition: all 0.4s ease;
}

.benefits-list ul li > .chk {
  width: 24px;
  height: 24px;
  background: url(../img/img-chk.png) 50% 50% no-repeat;
  background-size: cover;
  margin-right: 30px;
}
.benefits-list ul li:hover > .chk {
  background-image: url(../img/img-chk-on.png);
}
.benefits-list ul li p {
  margin-left: 60px;
  font-weight: 700;
  opacity: 0;
  transition: all 0.4s ease;
  font-size: 19px;
  color: #666;
}

.benefits-list ul li:hover {
  height: 130px;
  border: none;
}
.benefits-list ul li:hover ~ .benefits-list ul li {
  border: none;
}

.benefits-list ul li:hover > p {
  opacity: 1;
}

.benefits-list ul li:first-of-type:hover {
  background: url(../img/benefit-1.png) no-repeat 50% 50%;
  background-size: cover;
}

.benefits-list ul li:nth-child(2):hover {
  background: url(../img/benefit-2.png) no-repeat 50% 50%;
  background-size: cover;
}

.benefits-list ul li:nth-child(3):hover {
  background: url(../img/benefit-3.png) no-repeat 50% 50%;
  background-size: cover;
}

.benefits-list ul li:nth-child(4):hover {
  background: url(../img/benefit-4.png) no-repeat 50% 50%;
  background-size: cover;
}

.benefits-list ul li:nth-child(5):hover {
  background: url(../img/benefit-5.png) no-repeat 50% 50%;
  background-size: cover;
}

.benefits-list ul li:nth-child(6):hover {
  background: url(../img/benefit-6.png) no-repeat 50% 50%;
  background-size: cover;
}

.benefits-list ul li:nth-child(7):hover {
  background: url(../img/benefit-7.png) no-repeat 50% 50%;
  background-size: cover;
}

.career-wrapper2 .polygon {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
}

@keyframes hopAni {
  0%,
  40%,
  100% {
    transform: translateY(0);
  }

  20% {
    transform: translateY(-16px);
  }
}

.career-wrapper2.ani .benefits > span {
  animation: hopAni 0.8s 0.2s ease;
  animation-delay: calc(0.1s * var(--i));
}

.career-wrapper2.ani .title h1:not(.benefits) {
  overflow: hidden;
  height: 60px;
}

.career-wrapper2.ani .title h1:not(.benefits) > span {
  animation: showTitle 0.5s 1s ease forwards;
}

.benefits-list ul > li > h3:first-of-type {
  color: #888888;
}

.benefits-list ul > li:hover > h3:first-of-type {
  color: #4c4fff;
}

.career-wrapper3 {
  width: 100vw;
  height: 100vh;
  position: relative;
  background-color: #000120;
  display: flex;
  flex-direction: column;
  padding: 10vh 10vw 10vh 10vw;
  justify-content: center;
}

.career-wrapper3 .title {
  color: #fff;
}

.career-wrapper3 .title h3 {
  font-size: 20px;
  font-weight: 800;
  color: #888;
}

.career-wrapper3 .title h1 {
  font-size: 3.6rem;
  font-weight: 800;
  margin-bottom: 16px;
}

.career-wrapper3 .steps-wrapper {
  display: flex;
  width: 100%;
  height: calc(100% - 10vh - 110px);
  margin-top: 50px;
  position: relative;
}

.career-wrapper3 .steps-wrapper > div .line {
  position: absolute;
  height: 100%;
  top: 0;
  left: 0;
}

.career-wrapper3 .steps-wrapper > div {
  width: 35%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  color: #fff;
  overflow: hidden;
  /* padding: 5px 0; */
}
.career-wrapper3 .steps-wrapper > div:last-child {
  width: 30%;
}
.career-wrapper3 .steps-wrapper > div h3 {
  color: #3a4ada;
  width: 100%;
  text-align: left;
  font-size: 5rem;
  font-weight: 800;
  transform: translate(-100%, -2px);
}

.career-wrapper3 .steps-wrapper > div h4 {
  width: 100%;
  text-align: left;
  font-size: 3rem;
  font-weight: 700;
  margin: 20px 0px 30px 60px;
}

.career-wrapper3 .steps-wrapper > div h4 > span {
  color: #b9b9b9;
  font-size: 2.2rem;
  font-weight: 600;
}

.career-wrapper3 .steps-wrapper > div p {
  color: #ccc;
  font-size: 2rem;
  margin: 0 30px;
  width: calc(100% - 62px);
  line-height: 1.5;
}

.career-wrapper3 .steps-wrapper > div img:not(.line) {
  position: absolute;
  top: 60%;
  left: 50%;
  transform: translate(-50%, -60%);
  width: 130px;
}

@keyframes stepsAni {
  100% {
    transform: translate(-11.5px, -2px);
  }
}

.career-wrapper3.ani .steps-wrapper > div:first-child h3 {
  animation: stepsAni 1s ease forwards;
}

.career-wrapper3.ani .steps-wrapper > div:nth-child(2) h3 {
  animation: stepsAni 1s 0.3s ease forwards;
}

.career-wrapper3.ani .steps-wrapper > div:nth-child(3) h3 {
  animation: stepsAni 1s 0.6s ease forwards;
}

.career-wrapper4 {
  width: 100vw;
  height: 110vh;
  position: relative;
  background: url(../img/career-bg2.png) no-repeat 50% 50%;
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.career-wrapper4 .polygon {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}

.career-wrapper4 .title {
  color: #fff;
  text-align: center;
  opacity: 0;
  transform: translateY(-30px);
}

.career-wrapper4.ani .title {
  animation: showTitle 0.6s 0.2s ease forwards;
}

.career-wrapper4 .title h3 {
  font-size: 3.2rem;
  color: #3a4ada;
  font-weight: 900;
  margin-bottom: 3vh;
}

.career-wrapper4 .title h3 > span {
  width: 100px;
  border-top: 1px solid #3a4ada;
  vertical-align: middle;
  margin: 0 20px;
}

.career-wrapper4 .title h1 {
  font-size: 8rem;
  font-weight: 800;
  line-height: 1.2;
}

.career-wrapper4 .title h1 > span {
  color: #3a4ada;
}

.career-wrapper4 .email {
  display: flex;
  align-items: center;
  color: #fff;
  margin-top: 14vh;
  padding: 16px 20px;
  width: 50vw;
  border-top: 5px solid #3a4ada;
  border-bottom: 1px solid #666666;
  gap: 20px;
  font-size: 2rem;
  font-weight: 700;
}

.career-wrapper4 .email span {
  color: #d9d9d9;
  margin-left: 10px;
  font-size: 1.8rem;
  font-weight: 600;
}

.career-wrapper4 .map-link {
  color: #fff;
  padding: 10px 16px 10px 20px;
  border: 1px solid #fff;
  align-self: flex-end;
  margin-right: 25vw;
  font-weight: 800;
  font-size: 1.7rem;
  margin-top: 30px;
}

.career-wrapper4 .map-link::after {
  content: " ";
  width: 20px;
  height: 20px;
  display: inline-block;
  background: url(../img/arrow-r-blue.png) no-repeat 50% 50%;
  background-size: cover;
  vertical-align: text-bottom;
  margin-left: 8px;
}

/*about*/

.about-wrapper1 {
  width: 100vw;
  height: 100vh;
  position: relative;
  background: url(../img/about-main-bg.png) no-repeat 50% 80%;
  background-size: cover;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

@keyframes slideUp {
  100% {
    transform: none;
  }
}

.about-wrapper1 .sub-title {
  color: #888;
  text-align: center;
  font-weight: 700;
  margin-top: 20px;
  margin-bottom: 5vh;
  font-size: 3rem;
  text-transform: uppercase;
}

.about-wrapper1 .title {
  color: #fff;
  font-size: 10rem;
  font-weight: 800;
  text-align: center;
}

.about-wrapper1 .title h1 {
  white-space: nowrap;
  overflow: hidden;
  height: 100px;
  /* opacity: 0; */
}

.about-wrapper1 .title h1.first > span {
  transform: translateY(100px);
  animation: slideUp 0.6s 0.1s ease forwards;
}

.about-wrapper1 .title h1.second {
  transform: translateX(-140px);
  animation: slideUp 0.6s 1s ease forwards;
}

.about-wrapper1 .title h1.second > span {
  transform: translateY(100px);
  animation: slideUp 0.6s 0.4s ease forwards;
}

.about-wrapper1 .title > div {
  display: flex;
  justify-content: center;
}

.about-wrapper1 .title .third {
  width: 270px;
  overflow: hidden;
  color: #3a4ada;
}

.about-wrapper1 .title .third > span {
  transform: translateX(-270px);
  animation: slideUp 0.6s 1s ease forwards;
}

.about-wrapper1 .detail {
  color: #b9b9b9;
  margin-top: 6vh;
  text-align: center;
  font-weight: 600;
  font-size: 1.9rem;
}

.about-wrapper1 .detail p {
  line-height: 2;
}

/* .about-wrapper1 .title h1 {
  white-space: nowrap;
  transform: rotate(-10deg);
  text-align: right;
}

.about-wrapper1 .title h1.first {
  transform: rotate(-10deg) translateX(800px);
  margin-bottom: 40px;
  margin-right: 100px;
  animation: crossAni 0.8s 0.1s ease forwards;
}
.about-wrapper1 .title h1.second {
  transform: rotate(-10deg) translateX(-800px);
  animation: crossAni 0.8s 0.5s ease forwards;
}

.about-wrapper1 .title h1 > span {
  color: #3a4ada;
} */

.about-wrapper2 {
  width: 100vw;
  height: 80vh;
  position: relative;
  background-color: #000;
  overflow: hidden;
  background: url(../img/about-main-bg.png) no-repeat 50% 50%;
  background-size: cover;
}

.about-wrapper2 .title {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0;
}

.about-wrapper2 .title.ani {
  opacity: 1;
}

.about-wrapper2 .title > div {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
}

.about-wrapper2 .title > div > h1 {
  color: #fff;
  white-space: nowrap;
  font-size: 8rem;
  font-weight: 900;
}

.about-wrapper2 .title .sub-title {
  margin-top: 40px;
}

.about-wrapper2 .title .sub-title p {
  white-space: nowrap;
  line-height: 36px;
  color: #d9d9d9;
  text-align: center;
  opacity: 0;
  transition: all 1s 2.2s ease;
  font-size: 1.8rem;
  font-weight: 600;
}

.about-wrapper2 .title.ani .sub-title p {
  opacity: 1;
}

@keyframes about_ani1 {
  0% {
  }

  100% {
    transform: translateX(-200px);
  }
}

@keyframes about_ani2 {
  0% {
  }

  100% {
    transform: translateX(200px);
  }
}

@keyframes slideTxt-x {
  100% {
    transform: translateX(0);
  }
}

@keyframes slideTxt-y {
  100% {
    transform: translateY(0);
  }
}

.about-wrapper2 .title.ani .first {
  width: 274px;
  overflow: hidden;
}

.about-wrapper2 .title.ani .first > span {
  transform: translateX(-274px);
  animation: slideTxt-x 0.6s 0.2s ease forwards;
}

.about-wrapper2 .title.ani .second {
  width: 285px;
  overflow: hidden;
}

.about-wrapper2 .title.ani .second > span {
  transform: translateX(285px);
  animation: slideTxt-x 0.6s 0.6s ease forwards;
}

.about-wrapper2 .title.ani .third {
  height: 80px;
  overflow: hidden;
}

.about-wrapper2 .title.ani .third > span {
  transform: translateY(80px);
  animation: slideTxt-y 0.6s 1s ease forwards;
}

.about-wrapper2 .title.ani .fourth {
  width: 250px;
  overflow: hidden;
}

.about-wrapper2 .title.ani .fourth > span {
  transform: translateX(-250px);
  animation: slideTxt-x 0.6s 1.6s ease forwards;
}

.about-wrapper2 .title.ani .fifth {
  width: 206px;
  overflow: hidden;
}

.about-wrapper2 .title.ani .fifth > span {
  transform: translateX(206px);
  animation: slideTxt-x 0.6s 1.6s ease forwards;
}

.about-wrapper2 .title.ani .visual-1 {
  display: flex;
  width: 120px;
  height: 100px;
  overflow: hidden;
  margin: 0 30px;
  align-items: center;
}

.about-wrapper2 .title.ani .visual-1 > img {
  transform: translateX(120px);
  animation: slideTxt-x 0.6s 0.6s ease forwards;
}

.about-wrapper2 .title.ani .visual-2 {
  display: flex;
  align-items: center;
  width: 120px;
  height: 100px;
  overflow: hidden;
  margin: 0 30px;
}

.about-wrapper2 .title.ani .visual-2 > img {
  width: 100%;
  transform: translateY(100px);
  animation: slideTxt-y 0.6s 1.6s ease forwards;
}

.about-wrapper3 {
  position: relative;
  width: 100vw;
  height: 100vh;
  background: url(../img/history-bg.png) 50% 40% no-repeat;
  background-size: cover;
  padding-top: 90px;
}

.about-wrapper3 .history-top {
  border-bottom: 1px solid #666;
  width: 100%;
  display: flex;
  justify-content: center;
  position: relative;
}

.about-wrapper3 .history-top .history-prev {
  position: absolute;
  cursor: pointer;
  width: 50px;
  height: 50px;
  background: url(../img/year-slide-l.png) no-repeat 50% 50%;
  background-size: cover;
  left: 5%;
  top: 50%;
  transform: translateY(-60%);
}

.about-wrapper3 .history-top .history-next {
  position: absolute;
  cursor: pointer;
  width: 50px;
  height: 50px;
  background: url(../img/year-slide-r.png) no-repeat 50% 50%;
  background-size: cover;
  right: 5%;
  top: 50%;
  transform: translateY(-60%);
  display: none;
}

.about-wrapper3 .history-top .year {
  width: 80%;
  overflow: hidden;
  height: 100%;
  position: relative;
  display: flex;
}

.about-wrapper3 .history-top .year .year-wrapper {
  width: 100%;
  display: flex;
  padding: 30px 0;
  flex-grow: 1;
  flex-shrink: 0;
  transform: translateX(-75%);
  transition: all 0.8s ease;
}

.about-wrapper3 .history-top .year h3 {
  color: #454666;
  font-size: 4rem;
  font-weight: 900;
  cursor: pointer;
}

.about-wrapper3 .history-top .year > div > div {
  position: relative;
  width: 25%;
  text-align: center;
}

.about-wrapper3 .history-top .year-wrapper > div.on > h3 {
  color: #fff;
}

.year-wrapper > div:after {
  content: " ";
  position: absolute;
  top: 0;
  right: 0;
  width: 30px;
  height: 30px;
  background: url(../img/year-next.png) 50% 50% no-repeat;
  background-size: cover;
  display: inline-block;
}

.year-wrapper:first-child > div:last-child::after {
  display: none;
}

.year-wrapper:last-child > div:last-child::after {
  display: none;
}

.year-wrapper:last-child > div:first-child::before {
  content: " ";
  position: absolute;
  top: 0;
  left: -12px;
  width: 30px;
  height: 30px;
  background: url(../img/year-next.png) 50% 50% no-repeat;
  background-size: cover;
  display: inline-block;
}

.about-wrapper3 .history-bottom {
  position: relative;
  margin: 0 8%;
  height: calc(100% - 100px);
  display: flex;
}

.about-wrapper3 .history-bottom h1 {
  color: #3a4ada;
  font-size: 9rem;
  font-weight: 900;
  position: absolute;
  top: 40%;
  /* left: 8%; */
  transform: rotate(-90deg) translateY(-7%);
}

.about-wrapper3 .history-bottom .list {
  position: absolute;
  border-left: 1px solid #666;
  padding: 6% 0;
  margin: 0 0 4% 25%;
  width: 85%;
  height: 100%;
  /* display: none; */
  opacity: 0;
  /* overflow-y: clip; */
  gap: 10%;
  flex-direction: column;
  transition: all 0.6s ease;
}

.about-wrapper3 .history-bottom .list div:not(.next-btn) {
  color: #d9d9d9;
  font-weight: 600;
  font-size: 1.8rem;
  position: relative;
  padding: 0 0px 0 30px;
  margin-bottom: 3%;
  transition: all 0.6s ease;
}

.about-wrapper3 .history-bottom .list div:not(.next-btn)::before {
  content: " ";
  width: 10px;
  height: 10px;
  background-color: #3a4ada;
  border-radius: 50%;
  position: absolute;
  left: -5px;
  top: 50%;
  transform: translateY(-50%);
}

.about-wrapper3 .history-bottom .next-btn {
  position: absolute;
  width: 500px;
  height: 40px;
  left: 25%;
  bottom: 40px;
  background-color: rgba(217, 217, 217, 0.15);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  display: none;
}

.about-wrapper3 .border {
  position: absolute;
  height: 100%;
  width: 12px;
  background: #191a36;
  left: calc(50% + 50px);
}

.about-wrapper3 .history {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.about-wrapper3 .history > h1 {
  color: #fff;
  font-size: 22rem;
  font-weight: 900;
}

.about-wrapper3 .history-slider {
  height: 100%;
}

.about-wrapper3 .history-slider h1 {
  color: #1f1762;
  font-size: 22rem;
  font-weight: 900;
  /* margin: 5.4vh 0; */
  /* transform: translateY(39px); */
  transition: all 1s ease;
}

.about-wrapper3 .history-slider .slick-current.slick-active h1 {
  color: #6648ff;
}

.about-wrapper3 .history-slider .slick-current.slick-active .sub {
  color: #fff;
  opacity: 1;
}

.history .history-r {
  width: 60%;
  height: 100%;
}

.about-wrapper3 .history-slider .slick-track > h1:first-of-type {
  /* margin-top: 70px; */
}

.history .slick-prev.slick-arrow {
  color: #fff;
  position: absolute;
  top: calc(50% - 120px);
  z-index: 100;
}

.history .slick-next.slick-arrow {
  color: #fff;
  position: absolute;
  bottom: calc(50% - 120px);
  z-index: 100;
}

.history .slick-list {
  height: 100% !important;
}

.history-r button {
  position: absolute;
  z-index: 100;
  left: 36%;
  cursor: pointer;
}

.history-r button > img {
  width: 40px;
}

.history-r button.history-up {
  top: calc(50% - 180px);
}

.history-r button.history-down {
  bottom: calc(50% - 160px);
}

.history-slider .slide {
  display: flex !important;
  height: 40vh !important;
  align-items: center;
  gap: 16vw;
  position: relative;
  transform: translateY(25%);
}

.history-slider .slide .sub {
  color: #1f1762;
  height: 100%;
  display: flex;
  align-items: center;
  gap: 20px;
  font-size: 2rem;
  transition: all 1s ease;
  opacity: 0;
}

.history-slider .slide .sub:not(:first-of-type) {
  margin-top: 30px;
}

.history-slider .slide .sub h3 {
  font-weight: 900;
}

.history-slider .slide .sub h3 p {
  font-weight: 700;
}

/* .ci-wrapper {
  width: 100%;
  height: 100% !important;
  position: relative;
  overflow: hidden;
} */

.about-wrapper4 .ci-mov {
  position: absolute;
  width: 100vw;
  height: 100vh;
  object-fit: cover;
  z-index: -1;
}

.about-wrapper4 .title {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #fff;
  text-align: center;
  white-space: nowrap;
}

.about-wrapper4 .title h3 {
  font-size: 3rem;
  font-weight: 600;
  letter-spacing: 105%;
  margin-bottom: 40px;
}

.about-wrapper4 .title h1 {
  font-size: 6rem;
  font-weight: 900;
  overflow: hidden;
  height: 62px;
}

.about-wrapper4 .title h1 span {
  transition: all 0.4s ease;
}

.about-wrapper4 .ci-wrapper {
  position: absolute;
  width: 100vw;
  height: 100vh;
  background: #fff;
  top: 0;
}

.about-wrapper5 {
  width: 100vw;
  height: 100vh;
  position: relative;
  background: #fff;
  display: flex !important;
  flex-direction: column;
  justify-content: center;
}

.about-wrapper5 .ci {
  display: flex !important;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 80px;
}

.about-wrapper5 .ci-spell {
  display: flex;
  align-items: flex-end;
  font-size: 4.2rem;
  font-weight: 900;
  gap: 8vw;
}

.about-wrapper5 .ci-spell img {
  width: 100px;
}

.about-wrapper5 .ci-meaning {
  font-size: 2.4rem;
  color: #444;
  font-weight: 800;
}

.about-wrapper5 .ci-meaning p > span {
  width: 80px;
  border-top: 1px solid #000;
  vertical-align: middle;
  margin: 0 20px;
}

.about-wrapper5 .slide-btn {
  position: absolute;
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 0 10vw;
  z-index: 200;
}

.about-wrapper5 .slide-btn .ci-prev {
  width: 30px;
  height: 30px;
  background: url(../img/ico-prev.png) no-repeat 50% 50%;
  background-size: cover;
  cursor: pointer;
}

.about-wrapper5 .slide-btn .ci-next {
  width: 30px;
  height: 30px;
  background: url(../img/ico-next.png) no-repeat 50% 50%;
  background-size: cover;
  cursor: pointer;
}

.about-wrapper6 {
  position: relative;
  width: 100vw;
  height: 100vh;
  background-color: #000;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10vh;
  /* background: #0d0c2b url(../img/img-bg-vector.png) 50% 0% no-repeat; */
}

.about-wrapper6 > h1 {
  color: #fff;
  font-weight: 900;
  font-size: 4rem;
}

.about-wrapper6 .card-wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  gap: 3vw;
}

.about-wrapper6 .card-wrapper > div {
  position: relative;
  width: 18%;
  height: 48vh;
  background: url(../img/vision-card.png) 50% 50% no-repeat;
  background-size: cover;
  box-shadow: rgba(58, 74, 218, 0.4) 0 0 40px 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transition: all 0.6s 0.2s ease;
}

.about-wrapper6 .card-wrapper > div > img {
  width: 35%;
  margin-bottom: 30px;
  transition: all 0.6s 0.2s ease;
}

.about-wrapper6 .card-wrapper > div .sub-title {
  color: #797979;
  font-weight: 700;
  margin-bottom: 4px;
  transition: all 0.6s 0.2s ease;
}

.about-wrapper6 .card-wrapper > div h3 {
  color: #888;
  text-align: center;
  font-size: 2.8rem;
  font-weight: 800;
  line-height: 1.2;
  transition: all 0.3s 0.2s ease;
  word-break: keep-all;
  white-space: initial;
}

.about-wrapper6 .card-wrapper > div .vision-sub {
  position: absolute;
  color: #d9d9d9;
  text-align: center;
  /* font-size: 1.5rem; */
  opacity: 0;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-weight: 700;
  color: #fff;
  font-size: 2rem;
  transition: all 0.6s 0.2s ease;
  white-space: nowrap;
  line-height: 1.6;
}

.about-wrapper6 .card-wrapper > div:hover {
  background: url(../img/vision-card-on.png) 50% 50% no-repeat;
  background-size: cover;
  border: 4px solid #3a4ada;
}

.about-wrapper6 .card-wrapper > div:hover > .sub-title {
  margin-bottom: 5px;
  opacity: 0;
}

.about-wrapper6 .card-wrapper > div:hover > img {
  opacity: 0;
  transition: all 0.6s 0.2s ease;
}

.about-wrapper6 .card-wrapper > div:hover > h3 {
  color: #fff;
  font-size: 2.4rem;
  margin-bottom: 20px;
  letter-spacing: 1;
  opacity: 0;
}

.about-wrapper6 .card-wrapper > div:hover > .vision-sub {
  /* display: block; */
  opacity: 1;
}

.about-wrapper7 {
  width: 100vw;
  min-height: auto;
  background-color: #000120;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 20vh 10vw;
  position: relative;
}

.about-wrapper7 .title h1 {
  color: #fff;
  font-size: 4rem;
  font-weight: 900;
  margin-bottom: 10px;
}

.about-wrapper7 .title h3 {
  color: #888;
  font-size: 20px;
  font-weight: 800;
}

.about-wrapper7 .partners-box {
  margin-top: 100px;
}

.about-wrapper7 .partners-box > div {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 80px;
  padding: 0 5vw;
  position: relative;
}

.about-wrapper7 .partners-box img {
  width: 130px;
}

.about-wrapper7 .partners-box > div > div {
  background: #0d0c2b;
  padding: 10px 20px;
}

.about-wrapper7 .view-more {
  position: absolute !important;
  width: 20px;
  height: 20px;
  background: url(../img/ico-plus.png) no-repeat 50% 50%;
  background-size: cover;
  right: 10vw;
  bottom: calc(20vh + 100px);
  cursor: pointer;
}

.about-wrapper7 .view-more.min {
  width: 40px;
  height: 40px;
  background: #3a4ada url(../img/ico-minus.png) no-repeat 50% 50%;
  background-size: cover;
}

.about-wrapper8 {
  width: 100vw;
  height: 100vh;
  position: relative;
  background-color: #000120;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.about-wrapper8 .title {
  color: #fff;
  font-size: 5rem;
  font-weight: 800;
}

.about-wrapper8 .info {
  margin-top: 40px;
  border-top: 5px solid #3a4ada;
  color: #d9d9d9;
  width: 50vw;
}

.about-wrapper8 .info > div {
  display: flex;
  gap: 20px;
  align-items: center;
  padding: 24px 20px;
  border-bottom: 1px solid #666;
  font-size: 1.7rem;
  font-weight: 600;
}

.map-bg {
  position: relative;
  margin-top: 100px;
  margin-bottom: 30px;
  width: 80vw;
  height: 32vh;
  background-color: #3a4ada;
  transform: translate(20px, 20px);
}

.map-bg #map {
  position: absolute;
  width: 100%;
  height: 100%;
  bottom: 20px;
  right: 20px;
  z-index: 10;
}

.map-bg .map-filter {
  position: absolute;
  width: 100%;
  height: 100%;
  bottom: 20px;
  right: 20px;
  background-color: rgba(0, 1, 32, 0.4);
  z-index: 100;
}

.about-wrapper8 .map-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
}

.about-wrapper8 .map-wrapper .info {
  display: flex;
  padding: 0 0 0 10vw;
  flex-direction: column;
  justify-content: center;
  gap: 50px;
  width: 40%;
}

.about-wrapper8 .map-wrapper .map {
  width: 60%;
  position: relative;
}

.about-wrapper8 .map-wrapper .map-cover {
  position: absolute;
  width: 100%;
  height: 100%;
  background: url(../img/map-cover.png) no-repeat 50% 50%;
  background-size: cover;
  z-index: 10;
}

.about-wrapper8 .map-wrapper .info h3 {
  font-size: 4rem;
  font-weight: 900;
  margin-bottom: 20px;
}

.about-wrapper8 .map-wrapper .info p {
  font-size: 2rem;
  font-weight: 400;
}

/*Contact Page */

.contact-wrapper {
  position: relative;
  background-color: #000120;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 90px 10vw 90px 10vw;
  /* gap: 10vh; */
}

.map-bg:hover{

}

.contact-wrapper .title h1 {
  color: #fff;
  font-size: 5rem;
  font-weight: 900;
  margin-bottom: 15px;
}

.contact-wrapper .contact-box {
  display: flex;
  color: #d9d9d9;
  margin: 8vh 0 5vh 0;
}

.contact-wrapper .contact-box > div {
  display: flex;
  align-items: center;
  font-weight: 500;
  font-size: 2rem;
  gap: 20px;
  padding: 0 20px;
  border-right: 1px solid #666;
}

.contact-wrapper .contact-box > div:last-child {
  border: none;
}

.contact-wrapper .map-bg {
  margin-top: 0;
}

.contact-wrapper2 {
  width: 100vw;
  /* height: 120vh; */
  position: relative;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 10vh 0 0 0vw;
  /* align-items: center; */
}

.contact-wrapper2 .title {
  padding-left: 10vw;
}

.contact-wrapper2 .title .sub-title {
  position: relative;
  color: #3a4ada;
  font-weight: 800;
  font-size: 4rem;
  overflow: hidden;
  height: 170px;
}

.contact-wrapper2 .title .main {
  margin-top: 30px;
  color: #000120;
  font-size: 76px;
  font-weight: 800;
  line-height: 1.2;
}

.contact-wrapper2 .title .main h1 {
  height: 90px;
  overflow: hidden;
}

.contact-wrapper2 .title .main h1 > span {
  transform: translateY(90px);
  animation: slideUp 0.8s 0.6s ease forwards;
}

.contact-wrapper2 .title .main .blue {
  color: #3a4ada;
}

.contact-wrapper2 .title .main h1:not(:first-of-type) {
  display: inline-block;
}

.contact-wrapper2 .title .main h1:not(:first-of-type) > span {
  animation: slideUp 0.8s 1.3s ease forwards;
}

.contact-wrapper2 .email {
  display: flex;
  align-items: center;
  gap: 16px;
  color: #000120;
  font-weight: 700;
  font-size: 3.2rem;
  margin: 5vh 5px;
}

.contact-wrapper2 .link-wrapper {
  height: 45vh;
  position: relative;
  transition: all 1s ease;
  position: relative;
}

.contact-wrapper2 .link-wrapper > img {
  position: absolute;
  z-index: 100;
}

.contact-wrapper2 .link .product {
  width: 100%;
  height: 100%;
  transition: all 1s ease;
  background-size: cover;
  position: absolute;
  z-index: 10;
  display: flex;
  flex-direction: column;
}

.contact-wrapper2 .link .product.swb {
  background: url(../img/img-bus-1.png) no-repeat 50% 50%;
}

.contact-wrapper2 .link .product.qlik {
  background: url(../img/img-bus-3.png) no-repeat 50% 50%;
}

.contact-wrapper2 .link .product.tableau {
  background: url(../img/img-bus-2.png) no-repeat 50% 50%;
}

.contact-wrapper2 .email span {
  color: #b9b9b9;
  margin-left: 10px;
  font-size: 2.2rem;
  font-weight: 600;
}

.contact-wrapper2 > img {
  position: absolute;
  width: 100%;
  left: 0;
  bottom: -100%;
}

@keyframes growY {
  100% {
    height: 100%;
  }
}

.contact-wrapper2 .title .sub-title img {
  position: absolute;
  height: 0%;
  animation: growY 0.8s 0.2s ease forwards;
}

@keyframes slideDown {
  100% {
    opacity: 1;
    transform: translate(-6px, 138px);
  }
}

.contact-wrapper2 .title .sub-title h3 {
  transform: translate(-6px, 0);
  opacity: 0;
  animation: slideDown 0.8s 0.2s ease forwards;
}

.contact-wrapper2 .link {
  width: 100%;
  height: 45vh;
  background-color: #000120;
  bottom: 0;
  left: 0;
}

.link-wrapper .link > div {
  opacity: 0;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #fff;
}

.contact-wrapper2 .link > div.on {
  opacity: 1;
}

.link-wrapper .title {
  display: flex;
  align-items: center;
  padding: 0;
  margin-top: 58px;
}

.link-wrapper .link img {
  width: 46px;
  margin-right: 20px;
  margin-bottom: 4px;
}

.link-wrapper .link h3 {
  font-size: 4rem;
  font-weight: 800;
}

.link-wrapper .link a {
  background-color: #3a4ada;
  padding: 6px 30px 6px 32px;
  margin-top: 22px;
  color: #fff;
  font-weight: 800;
  border-radius: 30px;
  cursor: pointer;
  font-size: 1.9rem;
}
.link-wrapper .link a > p {
  display: inline-block;
  transform: translateY(2px);
}

.link-wrapper .link a::after {
  content: " ";
  width: 12px;
  height: 12px;
  background: url(../img/arrow-r.png) 50% 50% no-repeat;
  background-size: cover;
  display: inline-block;
  margin-left: 10px;
  vertical-align: middle;
}

.link-wrapper .slide-btn {
  position: absolute;
  right: 30px;
  top: 55%;
  transform: translateY(-55%);
  display: flex;
  flex-direction: column;
  gap: 20px;
  z-index: 110;
}

.link-wrapper .slide-btn a {
  width: 8px;
  height: 8px;
  background-color: #444444;
  border-radius: 50%;
  transition: all 1s ease;
}

.link-wrapper .slide-btn a.on {
  cursor: pointer;
  background-color: #fff;
}

@media all and (max-width: 820px) {
  .about-wrapper2 .title > div > h1 {
    font-size: 6rem;
  }

  .about-wrapper2 .title.ani .visual-1 {
    height: 50px;
  }

  .about-wrapper2 .title.ani .visual-2 {
    height: 50px;
  }

  .about-wrapper2 .title .sub-title {
    gap: 20px;
  }
  .contact-wrapper2 .title .sub-title {
    height: 163px;
  }
}

@media all and (max-width: 1080px) {
  header .business h3 {
    font-size: 14px;
  }
}
@media all and (max-width: 1400px) {
  .main-wrapper4 .detail-wrapper img {
    width: 16px;
  }
  .contact-wrapper2 .title .sub-title {
    height: 166px;
  }
}

@media all and (max-width: 1300px) {
  .benefits-list ul > li:first-child::before {
    right: -80px;
  }

  .benefits-list ul > li:first-child::after {
    right: 63px;
  }

  .benefits-list ul > li:nth-child(2)::before {
    width: 200px;
    height: 140px;
    top: -82px;
  }

  .benefits-list ul > li:nth-child(3):after {
    width: 240px;
    height: 200px;
  }

  .benefits-list ul > li:nth-child(4)::before {
    width: 126px;
    height: 206px;
    right: 264px;
  }

  .benefits-list ul > li:nth-child(4):after {
    width: 260px;
    height: 240px;
  }

  .benefits-list ul > li:nth-child(5):after {
    width: 190px;
    height: 272px;
  }

  .benefits-list ul > li:nth-child(6):after {
    width: 206px;
    height: 238px;
  }

  .benefits-list ul > li:nth-child(7)::before {
    width: 163px;
    height: 216px;
    top: -110px;
    right: 202px;
  }

  .benefits-list ul > li:nth-child(7):after {
    width: 180px;
    height: 180px;
    bottom: -50px;
    right: 60px;
  }
  .contact-wrapper2 .title .sub-title {
    height: 166px;
  }
}

@media all and (min-width: 1600px) {
  .main-wrapper .main-title h1 {
    font-size: 12rem;
    letter-spacing: 7px;
  }
  .about-wrapper1 .sub-title {
    margin-top: 12px;
    margin-bottom: 4vh;
    font-size: 3.2rem;
  }
  .about-wrapper1 .title {
    font-size: 11rem;
  }
  .about-wrapper1 .detail {
    margin-top: 7vh;
    font-weight: 500;
    font-size: 2.2rem;
  }
  .about-wrapper6 {
    height: 103vh;
    gap: 7vh;
  }
  .about-wrapper6 .vision-wrapper .vision img {
    width: 200px;
  }

  .about-wrapper6 .vision-wrapper .vision h1 {
    font-size: 5rem;
  }

  .about-wrapper6 .vision-wrapper .vision p {
    font-size: 2.4rem;
    margin-top: 10px;
  }

  .main-wrapper4 .category .txt-wrapper .title .line {
    width: 200px;
  }
  .about-wrapper7 .partners-box > div {
    margin-bottom: 70px;
  }
  .about-wrapper7 .partners-box > div > div {
    padding: 16px 46px;
  }
  .about-wrapper7 .view-more {
    bottom: calc(19vh + 100px);
  }
  .about-wrapper1 .title .third > span {
    transform: translateX(-300px);
    animation: slideUp 0.6s 1s ease forwards;
  }
  .about-wrapper1 .title .third {
    width: 300px;
  }

  .about-wrapper7 .title h1 {
    margin-bottom: 16px;
  }
  .about-wrapper3 .history-bottom h1 {
    font-size: 11.2rem;
    top: 37%;
    transform: rotate(-90deg) translateY(-19%);
  }
  .about-wrapper8 .info > div {
    font-size: 1.8rem;
  }
  .career-wrapper1 .title h1 {
    font-size: 8.8rem;
    line-height: 1.2;
  }
  .career-wrapper1 .title h3 {
    font-size: 2.4rem;
    line-height: 1.7;
  }
  .career-wrapper4 .map-link::after {
    margin-bottom: 2px;
  }
}
