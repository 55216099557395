@charset "utf-8";

/*---------- SpoqaHansSans ----------*/

@font-face {
  font-family: "SpoqaHansSansNeo";
  font-style: normal;
  font-weight: 300;
  src: url("../font/SpoqaHansSans/SpoqaHanSansNeo-Thin.ttf");
}

@font-face {
  font-family: "SpoqaHansSansNeo";
  font-style: normal;
  font-weight: 400;
  src: url("../font/SpoqaHansSans/SpoqaHanSansNeo-Light.ttf");
}
@font-face {
  font-family: "SpoqaHansSansNeo";
  font-style: normal;
  font-weight: 600;
  src: url("../font/SpoqaHansSans/SpoqaHanSansNeo-Regular.ttf");
}

@font-face {
  font-family: "SpoqaHansSansNeo";
  font-style: normal;
  font-weight: 700;
  src: url("../font/SpoqaHansSans/SpoqaHanSansNeo-Medium.ttf");
}

@font-face {
  font-family: "SpoqaHansSansNeo";
  font-style: normal;
  font-weight: 900;
  src: url("../font/SpoqaHansSans/SpoqaHanSansNeo-Bold.ttf");
}
