@charset "utf-8";
@import url("font.css");

/* Default Document Style */
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  background: transparent;
  box-sizing: border-box;
}

ol,
ul {
  list-style: none;
}

article,
aside,
canvas,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section,
summary {
  display: block;
}

blockquote,
q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}

a {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
  color: #000;
}

a:link,
a:hover,
a:visited,
a:active {
  text-decoration: none;
}

ins {
  background-color: #ff9;
  color: #000;
  text-decoration: none;
}

mark {
  background-color: #ff9;
  color: #000;
  font-style: italic;
  font-weight: bold;
}

del {
  text-decoration: line-through;
}

abbr[title],
dfn[title] {
  border-bottom: 1px dotted #000;
  cursor: help;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #cccccc;
  margin: 1em 0;
  padding: 0;
}

caption {
  width: 100%;
  height: 1px;
  color: transparent;
  overflow: hidden;
  position: relative;
}

input,
select {
  vertical-align: middle;
  font-family: inherit;
}

button {
  border: 0;
  padding: 0;
  margin: 0;
  background: transparent;
}

input,
textarea,
select,
button {
  font-family: inherit;
}

table {
  font-size: inherit;
}

pre,
code,
kbd,
samp,
tt {
  font-family: monospace;
  font-size: 108%;
  line-height: 100%;
}

legend {
  overflow: hidden;
  visibility: hidden;
  position: absolute;
  top: 0;
  left: 0;
  width: 1px;
  height: 1px;
  font-size: 0;
  line-height: 0;
}

img {
  vertical-align: top;
  border: 0;
  margin: 0;
  padding: 0;
  max-width: 100%;
}

span,
a,
strong,
i,
em {
  display: inline-block;
}

table {
  width: 100%;
}

strong {
  font-weight: 700;
}

p {
  line-height: 1.4;
}

html {
  font-size: 62.5%;
  height: 100%;
  /*scroll-behavior: smooth;*/
}

body {
  margin: 0;
  padding: 0;
  border: none;
  color: #000;
  font-size: 1.7rem;
  font-style: normal;
  background: transparent;
  height: 100%;
  width: 100%;
  line-height: 1;
  letter-spacing: -0.2px;
  font-weight: 400;
  font-family: "SpoqaHansSansNeo", sans-serif;
  transform-origin: center center;
  word-break: keep-all;
  overflow-x: hidden;
}

.blocking-time body {
  opacity: 0;
}

/* Text Style */
.txt-hidden {
  text-indent: -9999em;
  font-size: 0;
  color: transparent;
}

.txt-cnt {
  text-align: center !important;
}

.txt-left {
  text-align: left !important;
}

.txt-right {
  text-align: right !important;
}

.in-block {
  display: inline-block !important;
  vertical-align: middle !important;
}

/* Hidden */
.hidden,
legend {
  visibility: hidden;
  overflow: hidden;
  width: 0;
  height: 0;
  font-size: 0;
  line-height: 0;
  margin: 0;
  padding: 0;
  background: none;
}

/* Icon */
i {
  display: inline-block;
  vertical-align: middle;
  background-size: contain !important;
}

/* Border */
.bo-0 {
  border: 0 !important;
}

/* Spacing */
.p-0 {
  padding: 0 !important;
}

.pt-0 {
  padding-top: 0 !important;
}

.pt-20 {
  padding-top: 20px !important;
}

.pt-30 {
  padding-top: 30px !important;
}

.pt-40 {
  padding-top: 40px !important;
}

.pb-0 {
  padding-bottom: 0 !important;
}

.ph-20 {
  padding: 20px 0 !important;
}

.pw-20 {
  padding: 0 20px !important;
}

.m-0 {
  margin: 0 !important;
}

.mt-0 {
  margin-top: 0 !important;
}

.mt-10 {
  margin-top: 10px !important;
}

.mt-20 {
  /* margin-top: 20px !important; */
}

.mt-30 {
  margin-top: 30px !important;
}

.mt-40 {
  margin-top: 40px !important;
}

.mt-50 {
  margin-top: 50px !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.mb-10 {
  margin-bottom: 10px !important;
}

.mb-20 {
  margin-bottom: 20px !important;
}

.mb-30 {
  margin-bottom: 30px !important;
}

.mb-40 {
  margin-bottom: 40px !important;
}

.mb-50 {
  margin-bottom: 50px !important;
}

.mh-20 {
  margin: 20px 0 !important;
}

.mw-20 {
  margin: 0 20px !important;
}

.mr-10 {
  margin-right: 10px !important;
}

.mr-20 {
  margin-right: 20px !important;
}

.ml-10 {
  margin-left: 10px !important;
}

.ml-20 {
  margin-left: 20px !important;
}

.scrollbar-track-y {
  background: transparent !important;
}
.scrollbar-thumb {
  background: rgba(255, 255, 255, 0.3) !important;
}
@media all and (max-width: 1400px) {
  html {
    font-size: 52.5%;
  }
}

@media all and (max-width: 1200px) {
  html {
    font-size: 48.5%;
  }
}
